<template>
  <b-card title="Targetes virtuals pendents de cobrar">
    <client-payments-table
      :payments="payments"
      :loading="loadingPayments"
      show-search
      show-date
      show-client
    />
  </b-card>
</template>

<script>
import { BCard } from "bootstrap-vue";
import ClientPaymentsTable from "@/views/accounting/payments/virtual-cards/components/ClientPaymentsTable.vue";
import { notifyError } from "@/utils/methods";
import {
  formatDateStringToDatabaseDateTime,
  formatDateStringToDate,
} from "@/utils/formatters";

export default {
  components: {
    BCard,
    ClientPaymentsTable,
  },
  computed: {
    loadingPayments() {
      return this.$store.getters["clientPayments/loading"];
    },
    payments() {
      return this.$store.getters["clientPayments/payments"].map(
        (clientPayment) => {
          return {
            localizator: clientPayment.localizator,
            method: clientPayment.method,
            sortDate:
              formatDateStringToDatabaseDateTime(clientPayment.date) || null,
            date: this.formatDate(clientPayment.date) || null,
            bookingUuid: clientPayment.booking?.uuid || null,
            bookingLocalizator: clientPayment.booking?.localizator || null,
            bookingSource: clientPayment.booking?.source || null,
            bookingCancelled: clientPayment.booking?.cancelled || null,
            bookingHighlighted: clientPayment.booking?.highlighted || false,
            bookingFictitious: clientPayment.booking?.fictitious || false,
            accommodation: clientPayment.booking?.accommodation?.name || null,
            client: clientPayment.booking?.client?.fullName || null,
            total: clientPayment.pvpAmount,
          };
        }
      );
    },
  },
  created() {
    this.fetchPayments();
  },
  methods: {
    fetchPayments() {
      this.$store
        .dispatch("clientPayments/fetchPendingVirtualCardPayments")
        .catch(() => {
          notifyError(
            this.$t("errors.fetchClientPayments.title"),
            this.$t("errors.fetchClientPayments.description")
          );
        });
    },
    formatDate(date) {
      const formatting = {
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        timeZone: "Europe/Madrid",
      };
      return formatDateStringToDate(date, this.$i18n.locale, formatting);
    },
  },
};
</script>
