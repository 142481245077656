<template>
  <div>
    <!-- SEARCH -->
    <b-row
      v-if="showSearch"
      class="mb-2 d-flex flex-wrap justify-content-between"
    >
      <b-col
        cols="12"
        md="auto"
        class="d-flex mb-1 mb-md-0 justify-content-start"
      >
        <v-select
          v-model="otaFilter"
          :options="otaOptions"
          :reduce="(option) => option.value"
          placeholder="Canal"
          class="w-100 ota-filter"
        />
      </b-col>
      <b-col
        cols="12"
        md="auto"
        class="d-flex mb-1 mb-md-0 justify-content-end"
      >
        <b-button
          :disabled="isBusy"
          class="mr-1"
          variant="primary"
          size="sm"
          @click="printTable"
        >
          <feather-icon icon="PrinterIcon" />
        </b-button>
        <b-form-input
          id="filterInput"
          ref="filterInput"
          v-model="filter"
          type="search"
          :placeholder="`${$t('Cerca')}...`"
          :disabled="isBusy"
          debounce="400"
          autofocus
        />
      </b-col>
    </b-row>

    <!-- TABLE -->
    <b-row>
      <b-col md="12">
        <b-table
          striped
          borderless
          responsive
          class="position-relative"
          :busy.sync="isBusy"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          sort-icon-left
          :items="filteredPayments"
          show-empty
          :empty-text="$t('errors.emptyTable.title')"
          :empty-filtered-text="$t('errors.emptyTable.title')"
          :fields="fields"
          :filter="filter"
          :foot-clone="!!filteredPayments.length"
          no-footer-sorting
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-2" />
              <strong>{{ $t("Carregant") }}...</strong>
            </div>
          </template>

          <!-- Column: sortDate -->
          <template #cell(sortDate)="data">
            <span class="text-nowrap">
              {{ data.item.date }}
            </span>
          </template>

          <template #foot(sortDate)>
            <span />
          </template>

          <!-- Column: localizator -->
          <template #cell(bookingLocalizator)="data">
            <b-booking-localizator
              :localizator="data.value"
              :source="data.item.bookingSource"
              :highlighted="data.item.bookingHighlighted"
              :fictitious="data.item.bookingFictitious"
              :cancelled="data.item.bookingCancelled"
            />
            <div class="mt-25 text-nowrap">
              <small
                class="text-nowrap"
                :class="{
                  'text-warning': !isCurrentYear(data.item.bookingCheckin),
                }"
              >
                {{ formatDateStringToDate(data.item.bookingCheckin) }} -
                {{ formatDateStringToDate(data.item.bookingCheckout) }}
              </small>
            </div>
          </template>

          <template #foot(bookingLocalizator)>
            <span />
          </template>

          <template #foot(accommodation)>
            <span />
          </template>

          <!-- Column: total -->
          <template #cell(total)="data">
            <template v-if="data.value || data.value === 0">
              <span v-if="data.value > 0" class="text-success">
                +{{ formatCurrency(data.value) }}
              </span>
              <span v-if="data.value < 0" class="text-danger">
                {{ formatCurrency(data.value) }}
              </span>
              <span v-if="data.value === 0"> 0 € </span>
            </template>
            <span v-else> - </span>
          </template>

          <template #foot(total)>
            <span>{{ formatCurrency(filteredPaymentsTotalSum) }}</span>
          </template>

          <!-- Column: booking -->
          <template #cell(booking)="data">
            <template v-if="data.value || data.value === 0">
              <span v-if="data.value > 0">
                +{{ formatCurrency(data.value) }}
              </span>
              <span v-if="data.value < 0">
                {{ formatCurrency(data.value) }}
              </span>
              <span v-if="data.value === 0"> 0 € </span>
            </template>
            <span v-else> - </span>
          </template>

          <template #foot(booking)>
            <span>{{ formatCurrency(filteredPaymentsBookingSum) }}</span>
          </template>

          <!-- Column: securityDeposit -->
          <template #cell(securityDeposit)="data">
            <template v-if="data.value || data.value === 0">
              <span v-if="data.value > 0">
                +{{ formatCurrency(data.value) }}
              </span>
              <span v-if="data.value < 0">
                {{ formatCurrency(data.value) }}
              </span>
              <span v-if="data.value === 0"> 0 € </span>
            </template>
            <span v-else> - </span>
          </template>

          <template #foot(securityDeposit)>
            <span>{{
              formatCurrency(filteredPaymentsSecurityDepositSum)
            }}</span>
          </template>

          <!-- Column: touristTax -->
          <template #cell(touristTax)="data">
            <template v-if="data.value || data.value === 0">
              <div
                :id="`tourist-tax-description-${data.item.customId}-container`"
              >
                <div :id="`tourist-tax-description-${data.item.customId}`">
                  <span v-if="data.value > 0">
                    +{{ formatCurrency(data.value) }}
                  </span>
                  <span v-if="data.value < 0">
                    {{ formatCurrency(data.value) }}
                  </span>
                  <span v-if="data.value === 0"> 0 € </span>
                </div>
              </div>
            </template>
            <span v-else> - </span>
            <b-popover
              v-if="itemHasTouristTaxDescription(data.item)"
              title="Descripció Impost Turístic"
              :target="`tourist-tax-description-${data.item.customId}`"
              triggers="hover"
              placement="left"
              :container="`tourist-tax-description-${data.item.customId}-container`"
            >
              <ul>
                <li
                  v-for="(bookingService, index) in data.item
                    .touristTaxDescriptions"
                  :key="`popover-tourist-tax-${index}`"
                >
                  {{ getBookingServiceName(bookingService) }}
                  <ul>
                    <li>
                      PVP:
                      {{
                        getBookingServicePvpPrice(
                          bookingService,
                          data.item.bookingNights
                        ) || "No definit"
                      }}
                    </li>
                    <li>
                      Propietari:
                      {{
                        getBookingServiceCostPrice(
                          bookingService,
                          data.item.bookingNights
                        ) || "No definit"
                      }}
                    </li>
                  </ul>
                </li>
              </ul>
            </b-popover>
          </template>

          <template #foot(touristTax)>
            <span>{{ formatCurrency(filteredPaymentsTouristTaxSum) }}</span>
          </template>

          <!-- Column: services -->
          <template #cell(services)="data">
            <template v-if="data.value || data.value === 0">
              <div :id="`services-description-${data.item.customId}-container`">
                <div :id="`services-description-${data.item.customId}`">
                  <span v-if="data.value > 0">
                    +{{ formatCurrency(data.value) }}
                  </span>
                  <span v-if="data.value < 0">
                    {{ formatCurrency(data.value) }}
                  </span>
                  <span v-if="data.value === 0"> 0 € </span>
                </div>
              </div>
            </template>
            <span v-else> - </span>
            <b-popover
              v-if="itemHasServicesDescriptions(data.item)"
              title="Descripció serveis"
              :target="`services-description-${data.item.customId}`"
              triggers="hover"
              placement="left"
              :container="`services-description-${data.item.customId}-container`"
            >
              <ul>
                <li
                  v-for="(bookingService, index) in data.item
                    .servicesDescriptions"
                  :key="`popover-services-${index}`"
                >
                  {{ getBookingServiceName(bookingService) }}
                  <ul>
                    <li>
                      PVP:
                      {{
                        getBookingServicePvpPrice(
                          bookingService,
                          data.item.bookingNights
                        ) || "No definit"
                      }}
                    </li>
                    <li>
                      Propietari:
                      {{
                        getBookingServiceCostPrice(
                          bookingService,
                          data.item.bookingNights
                        ) || "No definit"
                      }}
                    </li>
                  </ul>
                </li>
              </ul>
            </b-popover>
          </template>

          <template #foot(services)>
            <span>{{ formatCurrency(filteredPaymentsServicesSum) }}</span>
          </template>

          <!-- Column: method -->
          <template #cell(method)="data">
            {{ getPaymentMethodName(data.value) }}
          </template>

          <template #foot(method)>
            <span />
          </template>

          <!-- Column: status -->
          <template #cell(status)="data">
            <b-badge
              pill
              :variant="data.value === 'CONFIRMED' ? 'success' : 'primary'"
            >
              {{ getClientPaymentStatusName(data.value) }}
            </b-badge>
          </template>

          <template #foot(status)>
            <span />
          </template>

          <!-- Column: localizator -->
          <template #cell(localizator)="data">
            <span v-if="data.value">{{ data.value }}</span>
          </template>

          <template #foot(localizator)>
            <span />
          </template>

          <!-- Column: actions -->
          <template #cell(actions)="data">
            <div class="d-flex justify-content-center">
              <b-button
                v-b-tooltip.hover="'Veure detalls dels pagaments'"
                size="sm"
                class="btn-icon mr-50"
                variant="primary"
                @click="
                  openClientPaymentsDetailsModal(data.item.clientPayments)
                "
              >
                <feather-icon icon="EyeIcon" />
              </b-button>

              <b-button
                v-b-tooltip.hover="'Verificar pagament'"
                size="sm"
                class="btn-icon"
                variant="primary"
                @click="onVerifyPayments(data.item.clientPayments)"
              >
                <feather-icon icon="CheckIcon" />
              </b-button>
            </div>
          </template>

          <template #foot(actions)>
            <span />
          </template>
        </b-table>
      </b-col>
    </b-row>

    <!-- MODAL -->
    <b-modal id="client-payments-details-modal" centered hide-footer>
      <h4 class="mb-2">Llistat de pagaments associats</h4>
      <ul>
        <li
          v-for="clientPayment in selectedClientPayments"
          :key="clientPayment.uuid"
          class="mb-1"
        >
          <div><b>Data:</b> {{ clientPayment.date }}</div>
          <div>
            <b>Import:</b> {{ formatCurrency(clientPayment.pvpAmount) }}
          </div>
          <div>
            <b>Mètode:</b>
            {{ getPaymentMethodName(clientPayment.method, $i18n.locale) }}
          </div>
          <div>
            <b>Concepte:</b>
            {{ getClientPaymentScopeName(clientPayment.scope, $i18n.locale) }}
          </div>
          <div v-if="clientPayment.comment">
            <b>Comentari:</b>
            {{ clientPayment.comment }}
          </div>
        </li>
      </ul>
    </b-modal>

    <!-- PDF TABLE -->
    <vue-html2pdf
      v-if="filteredPayments.length"
      ref="html2Pdf"
      :show-layout="false"
      :enable-download="false"
      :preview-modal="true"
      :pdf-quality="2"
      :manual-pagination="true"
      pdf-format="a4"
      pdf-orientation="landscape"
      pdf-content-width="794px"
      @progress="onProgress($event)"
    >
      <section slot="pdf-content">
        <!-- PDF Content Here -->
        <table border="1" class="m-5 pdf-table">
          <tr>
            <th>Reserva</th>
            <th>Allotjament</th>
            <th>Mètode</th>
            <th>Localitzador</th>
            <th>Reserva</th>
            <th>Fiança</th>
            <th>Impost turístic</th>
            <th>Serveis</th>
            <th>Total</th>
          </tr>
          <tr v-for="payment in filteredPayments" :key="payment.uuid">
            <td>
              <div>{{ payment.bookingLocalizator }}</div>
              <div class="mt-25 text-nowrap">
                <small
                  class="text-nowrap"
                  :class="{
                    'text-warning': !isCurrentYear(payment.bookingCheckin),
                  }"
                >
                  {{ formatDateStringToDate(payment.bookingCheckin) }} -
                  {{ formatDateStringToDate(payment.bookingCheckout) }}
                </small>
              </div>
            </td>
            <td>{{ payment.accommodation }}</td>
            <td>{{ getPaymentMethodName(payment.method) }}</td>
            <td>{{ payment.localizator }}</td>
            <td>
              <template v-if="payment.booking || payment.booking === 0">
                <span v-if="payment.booking > 0">
                  +{{ formatCurrency(payment.booking) }}
                </span>
                <span v-if="payment.booking < 0">
                  {{ formatCurrency(payment.booking) }}
                </span>
                <span v-if="payment.booking === 0"> - </span>
              </template>
              <span v-else>{{ $t("No definit") }}</span>
            </td>
            <td>
              <template
                v-if="payment.securityDeposit || payment.securityDeposit === 0"
              >
                <span v-if="payment.securityDeposit > 0">
                  +{{ formatCurrency(payment.securityDeposit) }}
                </span>
                <span v-if="payment.securityDeposit < 0">
                  {{ formatCurrency(payment.securityDeposit) }}
                </span>
                <span v-if="payment.securityDeposit === 0"> - </span>
              </template>
              <span v-else>{{ $t("No definit") }}</span>
            </td>
            <td>
              <template v-if="payment.touristTax || payment.touristTax === 0">
                <span v-if="payment.touristTax > 0">
                  +{{ formatCurrency(payment.touristTax) }}
                </span>
                <span v-if="payment.touristTax < 0">
                  {{ formatCurrency(payment.touristTax) }}
                </span>
                <span v-if="payment.touristTax === 0"> - </span>
              </template>
              <span v-else>{{ $t("No definit") }}</span>
            </td>
            <td>
              <template v-if="payment.services || payment.services === 0">
                <span v-if="payment.services > 0">
                  +{{ formatCurrency(payment.services) }}
                </span>
                <span v-if="payment.services < 0">
                  {{ formatCurrency(payment.services) }}
                </span>
                <span v-if="payment.services === 0"> - </span>
              </template>
              <span v-else>{{ $t("No definit") }}</span>
            </td>
            <td>
              <template v-if="payment.total || payment.total === 0">
                <span v-if="payment.total > 0">
                  +{{ formatCurrency(payment.total) }}
                </span>
                <span v-if="payment.total < 0">
                  {{ formatCurrency(payment.total) }}
                </span>
                <span v-if="payment.total === 0"> - </span>
              </template>
              <span v-else>{{ $t("No definit") }}</span>
            </td>
          </tr>
          <tr>
            <td colspan="4" />
            <td>{{ formatCurrency(filteredPaymentsBookingSum) }}</td>
            <td>{{ formatCurrency(filteredPaymentsSecurityDepositSum) }}</td>
            <td>{{ formatCurrency(filteredPaymentsTouristTaxSum) }}</td>
            <td>{{ formatCurrency(filteredPaymentsServicesSum) }}</td>
            <th>{{ formatCurrency(filteredPaymentsTotalSum) }}</th>
          </tr>
        </table>
      </section>
    </vue-html2pdf>
  </div>
</template>

<script>
import {
  BTable,
  BRow,
  BCol,
  BFormInput,
  BSpinner,
  BBadge,
  VBTooltip,
  VBPopover,
  BPopover,
  BButton,
  BModal,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { otaOptions } from "@/utils/select-options";
import BBookingLocalizator from "@foravila-core/components/b-booking-localizator/BBookingLocalizator.vue";
import { formatCurrency, formatDateStringToDate } from "@/utils/formatters";
import {
  getBookingServiceClientTotalPriceText,
  getBookingServiceOwnerTotalPriceText,
  getClientPaymentScopeName,
  getClientPaymentStatusName,
  getPaymentMethodName,
  getServiceName,
} from "@/utils/methods";
import VueHtml2pdf from "vue-html2pdf";

export default {
  components: {
    BTable,
    BRow,
    BCol,
    BFormInput,
    BSpinner,
    BBadge,
    BBookingLocalizator,
    vSelect,
    BPopover,
    BButton,
    VueHtml2pdf,
    BModal,
  },
  directives: {
    "b-tooltip": VBTooltip,
    "b-popover": VBPopover,
  },
  props: {
    showSearch: {
      type: Boolean,
      default: () => false,
    },
    loading: {
      type: Boolean,
      default: () => false,
    },
    payments: {
      type: Array,
      default: () => [],
    },
    showDate: {
      type: Boolean,
      default: () => false,
    },
    showStatus: {
      type: Boolean,
      default: () => false,
    },
    showLocalizator: {
      type: Boolean,
      default: () => false,
    },
    showMethod: {
      type: Boolean,
      default: () => false,
    },
    showClient: {
      type: Boolean,
      default: () => false,
    },
    showPriceBreakdown: {
      type: Boolean,
      default: () => false,
    },
    showActions: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      isBusy: true,
      sortBy: this.showDate ? "sortDate" : "bookingLocalizator",
      sortDesc: false,
      filter: null,
      fields: [],
      selectedBooking: null,
      otaFilter: null,
      formatDateStringToDate,
      selectedClientPayments: [],
    };
  },
  computed: {
    filteredPayments() {
      if (this.isBusy || !this.payments.length) return [];
      return this.payments.filter((payment) => {
        if (!this.otaFilter) return true;
        return payment.bookingSource === this.otaFilter;
      });
    },
    filteredPaymentsTotalSum() {
      if (!this.filteredPayments.length) return null;
      return this.filteredPayments.reduce((acc, payment) => {
        return (acc += payment.total || 0);
      }, 0);
    },
    filteredPaymentsBookingSum() {
      if (!this.filteredPayments.length) return null;
      return this.filteredPayments.reduce((acc, payment) => {
        return (acc += payment.booking || 0);
      }, 0);
    },
    filteredPaymentsSecurityDepositSum() {
      if (!this.filteredPayments.length) return null;
      return this.filteredPayments.reduce((acc, payment) => {
        return (acc += payment.securityDeposit || 0);
      }, 0);
    },
    filteredPaymentsTouristTaxSum() {
      if (!this.filteredPayments.length) return null;
      return this.filteredPayments.reduce((acc, payment) => {
        return (acc += payment.touristTax || 0);
      }, 0);
    },
    filteredPaymentsServicesSum() {
      if (!this.filteredPayments.length) return null;
      return this.filteredPayments.reduce((acc, payment) => {
        return (acc += payment.services || 0);
      }, 0);
    },
    otaOptions() {
      return otaOptions.filter((ota) => ota.highlighted);
    },
  },
  watch: {
    loading(loading) {
      this.isBusy = loading;
    },
  },
  created() {
    this.initTableFields();
  },
  methods: {
    initTableFields() {
      if (this.showDate) {
        this.fields.push({
          key: "sortDate",
          label: this.$t("Data"),
          sortable: true,
        });
      }
      this.fields.push({
        key: "bookingLocalizator",
        label: this.$t("Reserva nº"),
        sortable: true,
      });
      this.fields.push({
        key: "accommodation",
        label: this.$t("Allotjament"),
        sortable: true,
      });
      if (this.showClient) {
        this.fields.push({
          key: "client",
          label: this.$t("Client"),
          sortable: true,
          isActive: false,
        });
      }
      if (this.showMethod) {
        this.fields.push({
          key: "method",
          label: this.$t("Mètode"),
          sortable: true,
        });
      }
      if (this.showLocalizator) {
        this.fields.push({
          key: "localizator",
          label: this.$t("Localitzador"),
        });
      }
      if (this.showPriceBreakdown) {
        this.fields.push({
          key: "booking",
          label: this.$t("Reserva"),
          sortable: true,
        });
        this.fields.push({
          key: "securityDeposit",
          label: this.$t("Fiança"),
          sortable: true,
        });
        this.fields.push({
          key: "touristTax",
          label: this.$t("Impost turístic"),
          sortable: true,
        });
        this.fields.push({
          key: "services",
          label: this.$t("Serveis"),
          sortable: true,
        });
      }
      this.fields.push({
        key: "total",
        label: this.$t("Total"),
        sortable: true,
      });
      if (this.showStatus) {
        this.fields.push({
          key: "status",
          label: this.$t("Estat"),
          sortable: true,
        });
      }
      if (this.showActions) {
        this.fields.push({ key: "actions", label: this.$t("Accions") });
      }
    },
    onVerifyPayments(clientPayments) {
      this.$swal({
        title: "Verificar pagament",
        text: "Estàs a punt de verificar aquest pagament, estàs segur/a?",
        icon: "info",
        showCancelButton: true,
        confirmButtonText: "Verifica",
        cancelButtonText: "Cancel·la",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-primary ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          const uuids = clientPayments.map(
            (clientPayment) => clientPayment.uuid
          );
          this.$emit("verify-payments", uuids);
        }
      });
    },
    formatCurrency(amount) {
      return formatCurrency(amount);
    },
    getClientPaymentScopeName(code) {
      return getClientPaymentScopeName(code, this.$i18n.locale);
    },
    getClientPaymentStatusName(code) {
      return getClientPaymentStatusName(code, this.$i18n.locale);
    },
    getPaymentMethodName(code) {
      return getPaymentMethodName(code, this.$i18n.locale);
    },
    getBookingServiceName(bookingService) {
      if (!bookingService?.service) return null;
      return getServiceName(bookingService.service, this.$i18n.locale);
    },
    getBookingServicePvpPrice(bookingService, nights) {
      if (!bookingService?.pvpPrice) return null;
      return getBookingServiceClientTotalPriceText(
        bookingService,
        nights,
        this.$i18n.locale
      );
    },
    getBookingServiceCostPrice(bookingService, nights) {
      if (!bookingService?.costPrice) return null;
      return getBookingServiceOwnerTotalPriceText(
        bookingService,
        nights,
        this.$i18n.locale
      );
    },
    itemHasServicesDescriptions(item) {
      return item?.servicesDescriptions?.length || false;
    },
    itemHasTouristTaxDescription(item) {
      return item?.touristTaxDescriptions?.length || false;
    },
    isCurrentYear(dateString) {
      if (!dateString) return null;
      const currentYear = new Date().getFullYear();
      return dateString.includes(currentYear);
    },
    printTable() {
      this.$store.dispatch("app/setLoading", true);
      setTimeout(() => {
        this.$refs.html2Pdf.generatePdf();
      }, 100);
    },
    onProgress(percentage) {
      if (percentage === 100) this.$store.dispatch("app/setLoading", false);
    },
    openClientPaymentsDetailsModal(clientPayments) {
      this.selectedClientPayments = clientPayments;
      this.$bvModal.show("client-payments-details-modal");
    },
  },
};
</script>

<style scoped lang="scss">
.ota-filter {
  min-width: 200px;
}
.pdf-table td,
.pdf-table th {
  padding: 5px;
}
</style>
